var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid bg-img-dark"},[_c('div',{staticClass:"container mx-auto px-5 py-12 md:pt-32 md:pb-16"},[_c('div',{staticClass:"flex flex-wrap items-center"},[_vm._m(0),_c('div',{staticClass:"md:w-1/2 mt-5 md:mt-0"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"bg-blue-cobalt text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6L9 17l-5-5"}})])])]),_vm._m(3)]),_c('div',{staticClass:"flex mt-6"},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"bg-blue-cobalt text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6L9 17l-5-5"}})])])]),_vm._m(4)])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex md:w-1/2 items-center justify-center md:justify-end hidden md:flex",attrs:{"data-aos":"fade-right"}},[_c('img',{staticClass:"w-8/12 md:mr-16",attrs:{"src":require("@/assets/images/invoice-compressed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 text-left headline-2"},[_c('h2',[_vm._v("Send individual invoices or 50 invoices at once, all automated with robust invoicing capabilities")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center pb-5 md:hidden",attrs:{"data-aos":"fade-left"}},[_c('img',{staticClass:"w-8/12",attrs:{"src":require("@/assets/images/invoice-compressed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"home-subtitle-highlight"},[_vm._v("Everything Automated: ")]),_c('p',{staticClass:"home-subtitle"},[_vm._v(" All your timesheets, expenses, and bills are automatically collected and attached to your invoice email. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"home-subtitle-highlight"},[_vm._v("Flexibility on Invoice: ")]),_c('p',{staticClass:"home-subtitle"},[_vm._v(" Whether a client wants a single invoice for multiple employees or an invoice email for each employee or even a single PDF with multiple invoices, we've got you covered. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center mt-12 md:mt-16"},[_c('a',{staticClass:"w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer",attrs:{"onclick":"Calendly.initPopupWidget({url: 'https://calendly.com/vj--12'}); return false;"}},[_vm._v("Book a Demo Now")])])
}]

export { render, staticRenderFns }