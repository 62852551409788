<template>
  <div>
    <!-- Hero Section -->
    <hero></hero>

    <!-- Features -->
    <steps></steps>

    <feature-one></feature-one>

    <feature-two></feature-two>

    <feature-three></feature-three>

    <!-- appointment form -->
    <appointment-form></appointment-form>

    <!-- Mobile App Download Section  -->
    <device></device>
  </div>
</template>
<script>
import hero from './hero.vue';
import steps from '../steps.vue';
import device from './device.vue';
import featureOne from './feature-one.vue';
import featureTwo from './feature-two.vue';
import featureThree from './feature-three.vue';
import appointmentForm from './appointment-form.vue';
export default {
  components: { hero, steps, device, featureOne, featureTwo, featureThree, appointmentForm },

  mounted() {
    window.addEventListener('scroll', this.$parent.updateScroll);
  }
}
</script>