<template>
  <footer class="container-fluid bg-img-dark text-white">
    <div class="container mx-auto px-5 py-12 md:py-18">

      <div class="md:flex items-center justify-between">
        <div class="md:w-1/3 text-center md:text-left">
          <div class="flex-col justify-center">
            <a href="/" class="flex justify-center md:justify-start">
              <img src="@/assets/logo.png" alt="" class="w-56">
            </a>
          </div>
        </div>

        <div class="md:w-1/3">
              <h2 class="mb-4 mt-10 md:mt-0 text-center md:text-left"> Stay up to date with Temp Button</h2>
              <div>

                <form action="https://tempbutton.us20.list-manage.com/subscribe/post?u=0f1af948d61eea906fd353f8f&amp;id=ee56395bfc" method="post" target="_blank">
                  <div class="md:flex items-center">

                    <input type="email" value="" name="EMAIL" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 pl-5 md:mr-5 outline-none" placeholder="Email">

                    <button class="w-full md:w-56 px-2 py-2 mt-5 md:mt-0 md:border-2 md:border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
        </div>
      </div>

      <div class="flex-shrink md:flex justify-center md:justify-start items-center mt-5">
        <a href="#" class="footer-link mr-5">Staffing Agency Software</a>
        <a href="#" class="footer-link mr-5">Onboarding Software</a>
        <a href="#" class="footer-link mr-5">Timesheet Management System</a>
        <a href="#" class="footer-link mr-5">Payroll Management Software</a>
        <a href="#" class="footer-link mr-5">Temp Work Software</a>
      </div>

      <hr class="divide-gray-300 my-5">

      <div class="flex-shrink md:flex md:flex-shrink-0 md:justify-between items-center mb-5">
        <div class="flex justify-center md:justify-start items-center">
          <p class="footer-link mr-5">© 2021 Temp Button, Inc.</p>
          <a href="/privacy-policy" class="footer-link mr-5">Privacy Policy</a>
          <a href="/terms-and-conditions" class="footer-link mr-5">Terms and Conditions</a>
          <a href="#" class="footer-link mr-5">Responsible Disclosure</a>
        </div>

        <div class="flex justify-center md:justify-end mt-10 md:mt-0">
          <a href="https://www.facebook.com/Temp-Button-102048012350839" target="_blank">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 hover:text-gray-600" viewBox="0 0 24 24">
              <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
            </svg>
          </a>
          <a href="https://twitter.com/tempbutton" target="_blank" class="ml-3">
            <svg fill="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 hover:text-gray-600" viewBox="0 0 24 24">
              <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
            </svg>
          </a>
          <a href="https://www.instagram.com/tempbutton/" target="_blank" class="ml-3">
            <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-5 h-5 hover:text-gray-600" viewBox="0 0 24 24">
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5"></rect>
              <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
            </svg>
          </a>
          <a href="https://www.linkedin.com/company/80770325/" target="_blank" class="ml-3">
            <svg fill="currentColor" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="0" class="w-5 h-5 hover:text-gray-600" viewBox="0 0 24 24">
              <path stroke="none" d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z" ></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </a>
        </div>
      </div>

      <p class="footer-link text-gray-true-500 font-semibold">All product and company names are trademarks or registered trademarks of their respective holders. Use of them does not imply any affiliation with or endorsement by them.</p>

    </div>
  </footer>
</template>


<script>
export default {
  mounted() {
  }
}
</script>