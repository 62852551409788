import Vue from 'vue'
import App from './App.vue'

import VueTypedJs from 'vue-typed-js';
Vue.use(VueTypedJs);

import VueScrollactive from 'vue-scrollactive';
Vue.use(VueScrollactive);

import VModal from 'vue-js-modal';
Vue.use(VModal, { componentName: "modal" });

import AOS from 'aos';
import 'aos/dist/aos.css';

window.axios = require('axios');

Vue.config.productionTip = false


new Vue({
  created() {
    AOS.init()
  },
  data() {
    return {
      login_url: process.env.VUE_APP_LOGIN_URL,
    }
  },
  render: h => h(App),
}).$mount('#app')
