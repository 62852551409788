<template>
  <div id="app" class="overflow-x-hidden md:overflow-auto bg-black text-white">
    <!-- Top Navigation -->
    <header-section></header-section>

    <home v-if="selected_page === 'home'"></home>
    <privacy-policy v-if="selected_page === 'privacy-policy'"></privacy-policy>
    <terms-and-conditions v-if="selected_page === 'terms-and-conditions'"></terms-and-conditions>

    <!-- Footer Section -->
    <footer-section></footer-section>

    <!-- Signup Modal -->
    <signupModal></signupModal>
  </div>
</template>

<script>
import footerSection from './sections/new/footer.vue';
import headerSection from './sections/header.vue';
import signupModal from './components/signup.modal.vue';

import home from './sections/new/home.vue';
import privacyPolicy from "./sections/new/privacy-policy";
import termsAndConditions from "./sections/new/terms-and-conditions";

export default {
  components: { home, privacyPolicy, termsAndConditions, headerSection, footerSection,  signupModal},
  name: 'App',
  data() {
    return {
      selected_page: 'home',
      scrollPosition: null,
      navHeight: 'md:py-12'
    }
  },

  mounted() {
    //window.addEventListener('scroll', this.updateScroll);

    let pathname = window.location.pathname;
    if (pathname === '/privacy-policy') {
      this.selectPage('privacy-policy');
    } else if (pathname === '/terms-and-conditions') {
      this.selectPage('terms-and-conditions');
    }

  },

  watch: {
    scrollPosition (val) {
      if (val >= 0 && val <= 100) {
        this.navHeight = 'md:py-10';
      } else if (val >= 101 && val <= 200) {
        this.navHeight = 'md:py-8';
      } else if (val >= 201 && val <= 300) {
        this.navHeight = 'md:py-5';
      } else {
        this.navHeight = 'md:py-2';
      }
    }
  },

  methods: {
    openSignupModal() {
      this.$modal.show('signup-modal');
    },

    toogleNavMobile(e) {
      var nav = document.querySelector('#navMobile');
      nav.classList.toggle('hidden');
      e.preventDefault();
    },

    updateScroll() {
      this.scrollPosition = window.scrollY
    },

    selectPage(page_name) {
      this.selected_page = page_name;
    }

  }
}
</script>

<style src="@/assets/app.css">