<template>
  <header class="fixed w-full top-0 bg-header z-20">
    <div class="container mx-auto px-6 py-3 transition-all duration-300 ease-in" :class="[$parent.navHeight]">
      <div class="flex justify-between">
        <div class="flex items-center">
<!--            <a href="/" class="flex title-font font-medium text-2xl items-center md:justify-start justify-center text-white">
              Temp Button
            </a>-->

            
          <a href="/" class="flex items-center md:justify-start justify-center">
            <img src="@/assets/logo.png" alt="" class="w-40 md:w-56">
          </a>
        </div>
        <div class="hidden md:block">
          <div class="flex items-center">

            <a href="https://timesheet.avenueastaffing.com" class="text-center text-white hover:text-gray-true-300 font-semibold my-2 mr-5 py-2 transition-all duration-200 ease-in ml-3 cursor-pointer">Login</a>

            <a onclick="Calendly.initPopupWidget({url: 'https://calendly.com/tempbutton-booking/temp-button-demo-call'}); return false;" class="w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer">Book a Demo Now</a>
          </div>
        </div>

        <!-- mobile -->
        <div class="md:hidden">
          <div class="-mr-2 flex justify-between py-2">
            <div class="flex">
              <button @click="$parent.toogleNavMobile($event)" class="inline-flex items-center justify-center p-2 text-white focus:outline-none">
                <svg class="block h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" /></svg>
              </button>
            </div>
          </div>
        </div>

      </div>

      <div id="navMobile" class="hidden">
        <div class="flex justify-between items-center">
          <a href="https://timesheet.avenueastaffing.com" class="w-24 my-2 py-1 border-2 border-gray-200 text-gray-900 bg-gray-200 hover:bg-gray-300 hover:border-gray-200 rounded-full text-center transition-all duration-200 ease-in">Login</a>
          <a href="" onclick="Calendly.initPopupWidget({url: 'https://calendly.com/vj--12'});return false;" class="w-48 my-2 py-1 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in ml-3">Talk to an expert</a>
        </div>
      </div>
    </div>

  </header>
</template>

<script>
export default {};
</script>

<style>
.scrollactive-item {
  cursor: pointer;
}

.scrollactive-item.is-active {
  color: #3181cd;
}
</style>