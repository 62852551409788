<template>
  <section class="container-fluid bg-img-one mt-20 md:mt-32 pb-10" >
    <div class="container mx-auto px-5">
      <div class="text-center">
        <h3 class="font-GothamBook small-headline mb-8 -mt-12 text-blue-cobalt-dark">all in one, easy to use platform.</h3>

        <h1 class="font-GothamBold text-white headline-1">Staffing Made Easy.</h1>

        <div class="w-full mt-3">
          <div class="font-GothamBold grid grid-cols-2 items-center">
            <div>
              <h2 class="home-headline-2 text-white text-right pt-2 pr-1 md:pt-0 md:pr-2">All in one - </h2>
            </div>
            <div class="text-left">
              <vue-typed-js ref="typedJs" :loop="true" :strings="slogens" :typeSpeed="100" :backDelay="1500">
                <span class="typing home-headline-2 justify-start" id="story"></span>
              </vue-typed-js>
            </div>
          </div>
        </div>

        <p class="font-GothamBook text-white home-subtitle mt-5 md:mt-9">
          Temp button transforms complicated tech stacks for timesheets, invoices, and payroll <br class="hidden md:block"> into one simple elegant button. Preview it today.
        </p>

        <div class="flex items-center justify-center mt-5 md:mt-12">
          <a onclick="Calendly.initPopupWidget({url: 'https://calendly.com/tempbutton-booking/temp-button-demo-call'}); return false;" class="w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer">Book a Demo Now</a>
          <a href="https://timesheet.tempbutton.com/signup" class="w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer"> Try For Free </a>
        </div>

        <div class="flex justify-center mt-8 md:mt-16">
          <div class=" w-5/6">
            <video controls @play="videoPlayed()" class="z-10 w-auto video-border" id="video" poster="../../assets/images/thumbnail.png">
              <source src="@/assets/intro2.mp4" type="video/mp4"/>
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>

    <video-modal :displayModal="scrolled"></video-modal>
  </section>
</template>

<script>
import videoModal from "./video-modal.vue";

export default {
  components:{
    videoModal
  },
  data() {
    return {
      slogens: [
        'Onboarding',
        'Timesheet',
        'Approval',
        'Payroll',
        'Invoicing',
      ],
      currentSlide: 0,
      videoPlayer: true,
      scrolled: false
    }
  },

  methods: {
    videoPlayed(){
      this.videoPlayer = false;
    },
    handleScroll() {
      let yAxisHeight = Math.round(window.pageYOffset);
      if(yAxisHeight > 1000 && yAxisHeight < 2000 && this.videoPlayer){
        this.scrolled = true;
        this.videoPlayer = false;
      }
    }
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  mounted () {
    // this.$watch(
    //   () => {
    //     return this.$refs.typedJs.typedObj.arrayPos
    //   },
    // )
  },
};
</script>
