<template>
  <div class="container-fluid bg-img-light">
    <div class="container mx-auto px-5 py-12 md:py-32" id="talk-to-an-expert">
      <div class="flex justify-center">
        <div class="mt-10 w-full md:w-8/12">
          <div class="shadow-2xl rounded-lg">
            <div class="px-5 md:px-10 py-16 border-2 border-gray-true-200 rounded-lg text-white">
              <h2 class="md:mb-10 text-center text-2xl md:text-3xl">Talk to an expert</h2>
              <form action="https://tempbutton.us20.list-manage.com/subscribe/post?u=0f1af948d61eea906fd353f8f&amp;id=ee56395bfc" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div>
                  <div class="flex flex-col py-4">
                    <span class="px-5 text-lg">Name</span>
                    <input type="text" value="" name="NAME" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>
                  <div class="flex flex-col py-4">
                    <span class="px-5 text-lg">Business email</span>
                    <input type="email" value="" name="EMAIL" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>
                  <div class="flex flex-col py-4">
                    <span class="px-5 text-lg">Company name</span>
                    <input type="text" value="" name="COMPNAME" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>
                  <div class="flex flex-col py-4">
                    <span class="px-5 text-lg">Job title</span>
                    <input type="text" value="" name="JOBTITLE" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>
                  <div class="flex flex-col py-4">
                    <span class="px-5 text-lg">Phone number</span>
                    <input type="text" value="" name="PHONE" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>
                  <div class="flex flex-col py-4">
                    <span class="pl-5 text-lg">Best date to book a demo</span>
                    <input type="date" value="" name="date" class="w-full bg-gray-true-200 text-lg text-gray-900 rounded-full py-2 px-5">
                  </div>

                  <div class="flex items-center justify-center mt-5 md:mt-12">
                    <button type="submit" name="subscribe" class="w-full md:w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    mounted() {
    }
}
</script>