var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"steps"}},[_c('div',{staticClass:"container-fluid bg-img-light"},[_c('div',{staticClass:"container mx-auto px-5 py-12 md:pt-32 md:pb-16"},[_c('div',{staticClass:"flex flex-wrap items-center"},[_c('div',{staticClass:"md:w-1/2 mt-5 md:mt-0"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"bg-blue-cobalt text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6L9 17l-5-5"}})])])]),_vm._m(2)]),_c('div',{staticClass:"flex mt-6"},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"bg-blue-cobalt text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6L9 17l-5-5"}})])])]),_vm._m(3)]),_c('div',{staticClass:"flex mt-6"},[_c('div',{staticClass:"mt-1"},[_c('span',{staticClass:"bg-blue-cobalt text-white w-6 h-6 mr-2 rounded-full inline-flex items-center justify-center"},[_c('svg',{staticClass:"w-4 h-4",attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"3","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6L9 17l-5-5"}})])])]),_vm._m(4)])]),_vm._m(5)]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10 text-left headline-2"},[_c('h2',[_vm._v("Easy onboarding of employees")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center pb-5 md:hidden",attrs:{"data-aos":"fade-left"}},[_c('img',{staticClass:"w-8/12",attrs:{"src":require("@/assets/images/onboarding-compressed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"home-subtitle-highlight"},[_vm._v("Quick onboarding: ")]),_c('p',{staticClass:"home-subtitle"},[_vm._v(" Employees on-board themselves by adding details such as federal and state tax forms (ie., i9 w4) and banking info. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"home-subtitle-highlight"},[_vm._v("Employee Driven: ")]),_c('p',{staticClass:"home-subtitle"},[_vm._v(" This allows employees to conveniently edit and update their banking information, especially when they move. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"home-subtitle-highlight"},[_vm._v("Update from anywhere: ")]),_c('p',{staticClass:"home-subtitle"},[_vm._v(" Regardless of where employees work (office or remote location), work locations updated via timesheets and tax deductions (withholdings) are automatically updated. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex md:w-1/2 items-center justify-center md:justify-end hidden md:flex",attrs:{"data-aos":"fade-left"}},[_c('img',{staticClass:"w-8/12 md:mr-16",attrs:{"src":require("@/assets/images/onboarding-compressed.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex items-center justify-center mt-12 md:mt-16"},[_c('a',{staticClass:"w-56 py-2 border-2 border-blue-cobalt bg-blue-cobalt focus:outline-none rounded-full text-center text-white font-medium hover:border-blue-cobalt-dark hover:bg-blue-cobalt-dark transition-all duration-200 ease-in text-lg cursor-pointer",attrs:{"onclick":"Calendly.initPopupWidget({url: 'https://calendly.com/vj--12'}); return false;"}},[_vm._v("Book a Demo Now")])])
}]

export { render, staticRenderFns }