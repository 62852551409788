<template>
  <div class="container-fluid bg-gray-800 text-white">
    <div class="container mx-auto px-5 py-12 md:py-20">
    <div class="flex items-center md:flex-row flex-col">
      <div class="flex flex-col md:pr-10 md:mb-0 mb-6 pr-0 w-full md:w-auto md:text-left text-center">
        <h2 class="small-footer-headline tracking-widest font-medium title-font mb-1">Access Your All In One App From Anywhere.</h2>
        <h1 class="md:text-3xl text-2xl font-medium title-font text-white">Get Temp Button App Now</h1>
      </div>
      <div class="flex md:ml-auto md:mr-0 mx-auto items-center flex-shrink-0 space-x-4">
        <a href="https://play.google.com/store/apps/details?id=com.avenueastaffing.app" target="_blank" class="inline-flex py-3 px-5 rounded-lg items-center focus:outline-none">
          <img src="@/assets/images/playstore-icon.png" alt="" class="w-40 md:w-56">
        </a>
        <a href="https://apps.apple.com/us/app/avenue-a-staffing/id1516505380" target="_blank" class="inline-flex py-3 px-5 rounded-lg items-center focus:outline-none">
          <img src="@/assets/images/IOS-icon.png" alt="" class="w-40 md:w-56">
        </a>
      </div>
    </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>