<template>
  <div>
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex ">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-transparent outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between ">
            <button class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" v-on:click="toggleModal()">
              <span class="bg-transparent text-white opacity-5 h-6 w-6 text-4xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <video controls autoplay muted loop class="z-10 w-auto video-border" id="video" poster="../../assets/images/thumbnail.png">
                <source src="@/assets/intro2.mp4" type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
export default {
  name: "video-modal",
  props: ["displayModal"],
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    toggleModal: function(){
      this.showModal = !this.showModal;
    }
  },
  created(){
      this.showModal = this.displayModal ? true : false;
  }, 

  watch:{
      displayModal(val){
          this.showModal = val ? true : false;
      }
  }
}
</script>